'use client';
/* eslint-disable react-hooks/rules-of-hooks */
import { usePathname as useNextLocation } from 'next/navigation';
import { useLocation as useReactDomLocation } from 'react-router-dom';
import { isChromeExtension } from '@reshima/shared';

export function useLocation() {
  const location = isChromeExtension()
    ? useReactDomLocation().pathname
    : useNextLocation();

  return location;
}
