type Params<T> = {
  items: T[];
  idField: keyof T;
  idValue: T[keyof T];
  index: number;
};

export function ensureItemAtIndex<T>({
  items,
  idField,
  idValue,
  index,
}: Params<T>): T[] {
  const shallowItems = [...items];

  const focusedItemIndex = items.findIndex((item) => item[idField] === idValue);

  if (focusedItemIndex === -1 || focusedItemIndex === index) {
    return shallowItems;
  }

  const [movedItem] = shallowItems.splice(focusedItemIndex, 1);
  shallowItems.splice(index, 0, movedItem);

  return shallowItems;
}
