import { ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
  checked: boolean;
  ariaLabel: string;
  className?: string;
  label?: ReactNode;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function Checkbox({
  checked,
  ariaLabel,
  className,
  label,
  onChange,
}: Props) {
  return (
    <label className="flex items-center gap-2 cursor-pointer">
      <input
        type="checkbox"
        aria-label={ariaLabel}
        className={classNames(
          className,
          'w-5 h-5',
          'cursor-pointer',
          'text-primary-base',
          'bg-base-100',
          'border-base-content rounded',
          'focus:ring-primary-base focus:ring-offset-base-100',
        )}
        checked={checked}
        onChange={onChange}
      />
      {label}
    </label>
  );
}
