export enum Units {
  pcs = 'pcs',
  pks = 'pks',
  kg = 'kg',
  g = 'g',
  l = 'l',
  ml = 'ml',
}

export function isUnit(value?: unknown): value is Units {
  return Object.values(Units).includes(value as Units);
}
