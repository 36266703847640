'use client';
/* eslint-disable react-hooks/rules-of-hooks */
import { useSearchParams as useNextSearchParams } from 'next/navigation';
import { useSearchParams as useReactDomSearchParams } from 'react-router-dom';
import { isChromeExtension } from '@reshima/shared';

export function useSearchParams() {
  const params = isChromeExtension()
    ? useReactDomSearchParams()[0]
    : useNextSearchParams();

  return {
    get(key: string) {
      return params.get(key);
    },
  };
}
