'use client';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

declare global {
  interface Window {
    reCaptchaCallback?: () => void;
  }
}

export interface ReCaptchaProps {
  sitekey: string;
  theme: 'light' | 'dark';
  onReady: () => void;
}

export interface ReCaptchaRef {
  execute: () => Promise<string>;
}

interface ExecutionCallback {
  resolve: (token: string) => void;
  reject: () => void;
}

const scriptId = 'recaptcha-script';
const reCaptchaCallback = 'reCaptchaCallback';
const reCaptchaScript = `https://www.recaptcha.net/recaptcha/enterprise.js?onload=${reCaptchaCallback}&render=explicit`;

export const ReCaptcha = forwardRef<ReCaptchaRef, ReCaptchaProps>(
  function ReCaptcha({ sitekey, theme, onReady }: ReCaptchaProps, ref) {
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const container = useRef<HTMLDivElement>(null);
    const executionCallback = useRef<ExecutionCallback>();

    const loadScript = useCallback(() => {
      window[reCaptchaCallback] = () => {
        setIsScriptLoaded(true);
        onReady();
      };
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.src = reCaptchaScript;
        script.id = scriptId;
        document.head.appendChild(script);
      }
    }, [onReady]);

    function unloadScript() {
      document.getElementById(scriptId)?.remove();
    }

    async function execute(): Promise<string> {
      const promise = new Promise<string>((resolve, reject) => {
        executionCallback.current = { resolve, reject };
      });

      grecaptcha.enterprise.execute();

      return promise;
    }

    function callback(token: string) {
      executionCallback.current?.resolve(token);
      grecaptcha.enterprise.reset();
    }

    function errorCallback() {
      executionCallback.current?.reject();
    }

    useEffect(() => {
      loadScript();

      return () => {
        unloadScript();
      };
    }, [loadScript]);

    useEffect(() => {
      if (isScriptLoaded && container.current) {
        const recaptchaContainer = document.createElement('div');
        container.current.replaceChildren(recaptchaContainer);
        grecaptcha.enterprise.render(recaptchaContainer, {
          sitekey,
          theme,
          size: 'invisible',
          callback,
          'error-callback': errorCallback,
        });
      }
    }, [isScriptLoaded, sitekey, theme]);

    useImperativeHandle(ref, () => ({
      execute,
    }));

    return <div ref={container} />;
  }
);
