'use client';
import { useCallback, useEffect, useState } from 'react';
import { debouncedCallback } from '@reshima/shared';
import { EditableText } from './editable-text';

type Params = {
  ariaLabel: string;
  value?: string;
  delay: number;
  placeholder?: string;
  className?: string;
  maxLength?: number;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

export function DebouncedEditableText({
  ariaLabel,
  value,
  placeholder,
  className,
  delay,
  maxLength,
  onChange,
  onFocus,
  onBlur,
}: Params) {
  const [currentValue, setCurrentValue] = useState(value);

  const handleDebouncedChange = useCallback(
    debouncedCallback<string>({
      callback: onChange,
      delay,
    }),
    [onChange, delay],
  );

  const handleChange = (value: string) => {
    setCurrentValue(value);
    handleDebouncedChange(value);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <EditableText
      ariaLabel={ariaLabel}
      value={currentValue}
      onChange={handleChange}
      placeholder={placeholder}
      className={className}
      maxLength={maxLength}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}
