import { ICustomProperties, Instance, Options } from './models';
import { Action, ActionModifier } from './constants';
import { isWeb } from './utils';

let options: Options;
let instance: Instance;

const getRuntimeInstance = async (options: Options): Promise<Instance> => {
  const telemetryModule = isWeb()
    ? import('./web-telemetry')
    : import(/* webpackIgnore: true */ './node-telemetry');

  const { getInstance } = await telemetryModule;
  return getInstance(options);
};

export async function initialize(initOptions: Options): Promise<void> {
  if (instance) return;

  options = initOptions;

  instance = await getRuntimeInstance(options);
}

function createProperties({
  action,
  actionModifier,
  properties = {},
  start,
}: {
  action: Action | string;
  actionModifier: ActionModifier;
  properties?: ICustomProperties;
  start?: number;
}): ICustomProperties {
  return {
    ...options.initialProperties(),
    ...properties,
    action,
    actionModifier,
    ...(start && { duration: Date.now() - start }),
  };
}

export function trackEvent({
  name,
  action,
  actionModifier,
  properties = {},
  start,
}: {
  name: string;
  action: Action | string;
  actionModifier: ActionModifier;
  properties?: ICustomProperties;
  start?: number;
}): number {
  if (!instance) return Date.now();

  const event = {
    name,
    properties: createProperties({ action, actionModifier, properties, start }),
  };

  instance.trackEvent(event);

  if (options.consoleTraces) {
    const log = {
      name,
      action,
      actionModifier,
      ...properties,
    };
    console.log(typeof window !== 'undefined' ? log : JSON.stringify(log));
  }

  return Date.now();
}

export function trackException({
  name,
  action,
  error,
  properties = {},
  start,
}: {
  name: string;
  action: Action | string;
  error: unknown;
  properties?: ICustomProperties;
  start?: number;
}): void {
  if (!instance) return;

  const exception = { exception: error as Error };
  const customProperties = createProperties({
    action,
    actionModifier: ActionModifier.Fail,
    properties,
    start,
  });

  trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Fail,
    properties,
    start,
  });

  instance.trackException(exception, {
    ...customProperties,
    name,
  });

  if (options.consoleTraces) {
    console.error(error);
  }
}

export async function trackOperation({
  name,
  action,
  properties = {},
  operation,
}: {
  name: string;
  action: Action | string;
  properties?: ICustomProperties;
  start?: number;
  operation: () => void | ICustomProperties | Promise<void | ICustomProperties>;
}): Promise<void> {
  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const result = await operation();

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties: {
        ...properties,
        ...(result as ICustomProperties),
      },
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}
