import { forwardRef, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { StopClickPropagation } from '../stop-click-propagation';
import { Button } from '../button';
import { Option } from './models';

type SelectOptionsProps = {
  options: Option[];
  value?: string;
  optionsClassName?: string;
  optionClassName?: string;
  search?: boolean;
  placeholder?: string;
  noResultsMessage?: ReactNode;
  onChange: (value?: string) => void;
};

export const SelectOptions = forwardRef<HTMLInputElement, SelectOptionsProps>(
  function SelectOptions(
    {
      options,
      value,
      optionsClassName,
      optionClassName,
      search,
      placeholder,
      noResultsMessage,
      onChange,
    }: SelectOptionsProps,
    ref,
  ) {
    const [searchValue, setSearchValue] = useState('');

    const filteredOptions =
      search && searchValue
        ? options.filter((option) => option.text?.includes(searchValue))
        : options;

    return (
      <div className="flex flex-col gap-2">
        {search && (
          <StopClickPropagation className="w-full">
            <input
              ref={ref}
              className="w-full px-2 py-1 bg-base-100 border border-base-300 rounded-md"
              placeholder={placeholder}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              autoFocus
            />
          </StopClickPropagation>
        )}
        <div className={classNames('flex flex-col', optionsClassName)}>
          {filteredOptions.length ? (
            filteredOptions.map((option, index) => {
              const isSelected = option.value === value;
              return (
                <Button
                  ghost
                  className={classNames(
                    'text-right',
                    {
                      'font-semibold bg-base-200': isSelected,
                    },
                    optionClassName,
                  )}
                  key={index}
                  onClick={() => {
                    if (!isSelected) {
                      onChange(option.value);
                    }
                  }}
                >
                  {option.longLabel}
                </Button>
              );
            })
          ) : (
            <StopClickPropagation>{noResultsMessage}</StopClickPropagation>
          )}
        </div>
      </div>
    );
  },
);
