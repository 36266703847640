'use client';
import { PropsWithChildren, FC, createContext, useContext } from 'react';
import { Dictionary } from '@reshima/translations';

interface TranslationsContext {
  dictionary: Dictionary;
}

export const context = createContext<TranslationsContext>({
  dictionary: {} as Dictionary,
});

export function useTranslations(): Dictionary {
  return useContext(context).dictionary;
}

export function TranslationsProvider({
  dictionary,
  children,
}: PropsWithChildren<{
  dictionary: Dictionary;
}>): ReturnType<FC> {
  return (
    <context.Provider
      value={{
        dictionary,
      }}
    >
      {children}
    </context.Provider>
  );
}
