import classNames from 'classnames';

type ProgressBarProps = {
  current: number;
  total: number;
};

export function ProgressBar({ current, total }: ProgressBarProps) {
  const percentage = Math.round((current / total) * 100);
  return (
    <div className="relative w-full h-6 bg-base-200 rounded-lg">
      <div
        className={classNames(
          'absolute top-0 bottom-0 start-0 z-0',
          'bg-primary-base rounded-lg',
          'transition-width duration-500',
        )}
        style={{ width: `${percentage}%` }}
      ></div>
      <div
        className={classNames(
          'absolute z-10',
          'left-1/2 -translate-x-1/2',
          'top-1/2 -translate-y-1/2',
          'text-primary-content',
        )}
      >
        {percentage}% ({current}/{total})
      </div>
    </div>
  );
}
