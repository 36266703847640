'use client';
import NextLink from 'next/link';
import { Link as ReactRouterLink } from 'react-router-dom';
import { isChromeExtension } from '@reshima/shared';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string;
  keepSearch?: boolean;
};

export function Link(props: Props) {
  const { href, keepSearch, ...otherProps } = props;

  const url =
    typeof window !== 'undefined' && keepSearch
      ? {
          pathname: href,
          search: window.location.search,
        }
      : href;

  if (isChromeExtension()) {
    return <ReactRouterLink to={url} {...otherProps} />;
  }

  return <NextLink href={url} {...otherProps} />;
}
