'use client';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import classNames from 'classnames';
import { debouncedCallback } from '@reshima/shared';

type Props = {
  ariaLabel: string;
  value?: string;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  selectOnFocus?: boolean;
  onChange: (newName: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

function getTextAreaComputedStyle({
  textArea,
  property,
}: {
  textArea: HTMLTextAreaElement;
  property: string;
}) {
  return +getComputedStyle(textArea)
    .getPropertyValue(property)
    .replace('px', '');
}

function getTextAreaBorderWidth(textArea: HTMLTextAreaElement) {
  return (
    getTextAreaComputedStyle({ textArea, property: 'border-top-width' }) +
    getTextAreaComputedStyle({ textArea, property: 'border-bottom-width' })
  );
}

export const EditableText = forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => {
    const {
      ariaLabel,
      value = '',
      className,
      placeholder,
      maxLength,
      selectOnFocus,
      onChange,
      onFocus,
      onBlur,
    } = props;
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => textAreaRef.current as HTMLTextAreaElement);

    const adjustHeight = useCallback(() => {
      if (!textAreaRef.current) {
        return;
      }

      const textArea = textAreaRef.current;

      textArea.style.height = '0px'; // Reset the height to get accurate scrollHeight

      const { scrollHeight } = textArea;

      const borderWidth = getTextAreaBorderWidth(textArea);

      textArea.style.height = `${scrollHeight + borderWidth}px`;
    }, []);

    useEffect(() => {
      adjustHeight();

      const onResize = debouncedCallback({
        callback: adjustHeight,
        delay: 200,
      });

      window.addEventListener('resize', onResize);

      return () => {
        window.removeEventListener('resize', onResize);
      };
    }, [adjustHeight, value]);

    function onKeyDown({ key }: React.KeyboardEvent<HTMLTextAreaElement>) {
      if (key === 'Escape') {
        textAreaRef.current?.blur();
      }

      if (key === 'Enter') {
        textAreaRef.current?.blur();
      }
    }

    return (
      <textarea
        ref={textAreaRef}
        aria-label={ariaLabel}
        className={classNames('w-full resize-none', className)}
        value={value}
        placeholder={placeholder}
        rows={1}
        onChange={({ target: { value } }) => onChange(value)}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
        onBlur={() => onBlur?.()}
        onFocus={() => {
          if (selectOnFocus) textAreaRef.current?.select();
          onFocus?.();
        }}
      />
    );
  },
);
