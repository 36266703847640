import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}>;

export function StopClickPropagation({ className, children, onClick }: Props) {
  return (
    <div
      className={className}
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.(e);
      }}
    >
      {children}
    </div>
  );
}
