'use client';
import { useEffect, useState } from 'react';

export interface IsOnlineValues {
  isOnline: boolean;
}

export function useIsOnline(): IsOnlineValues {
  const [isOnline, setOnlineStatus] = useState(
    typeof window !== 'undefined' && window.navigator.onLine
  );

  useEffect(() => {
    const toggleOnlineStatus = () => setOnlineStatus(window.navigator.onLine);

    window.addEventListener('online', toggleOnlineStatus);
    window.addEventListener('offline', toggleOnlineStatus);

    return () => {
      window.removeEventListener('online', toggleOnlineStatus);
      window.removeEventListener('offline', toggleOnlineStatus);
    };
  }, [isOnline]);

  return {
    isOnline,
  };
}
