'use client';
import { debouncedCallback } from '@reshima/shared';
import { useCallback, useState } from 'react';

type Params = {
  value?: string;
  delay: number;
  placeholder?: string;
  className?: string;
  id?: string;
  disabled?: boolean;
  maxLength?: number;
  onChange: (value: string) => void;
};

export function DebouncedInput({
  id,
  value,
  placeholder,
  className,
  delay,
  disabled,
  maxLength,
  onChange,
}: Params) {
  const [currentValue, setCurrentValue] = useState(value);

  const handleDebouncedChange = useCallback(
    debouncedCallback<string>({
      callback: onChange,
      delay,
    }),
    [onChange, delay],
  );

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(value);
    handleDebouncedChange(value);
  };

  return (
    <input
      type="text"
      id={id}
      className={className}
      value={currentValue}
      onChange={handleChange}
      placeholder={placeholder}
      disabled={disabled}
      maxLength={maxLength}
    />
  );
}
