type Params<T> = {
  callback: (value: T) => void;
  delay?: number;
};

export function debouncedCallback<T>({ callback, delay = 300 }: Params<T>) {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (value: T) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      callback(value);
    }, delay);
  };
}
