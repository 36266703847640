import { ReactNode } from 'react';
import { GoChevronDown } from 'react-icons/go';
import { Option } from './models';

export function SelectContainer({
  selectedOption,
  placeholder,
  chevron,
}: {
  selectedOption?: Option;
  placeholder?: ReactNode;
  chevron: boolean;
}) {
  return (
    <div className="flex items-center gap-1">
      <div>{selectedOption?.shortLabel || placeholder}</div>
      {chevron && (
        <div>
          <GoChevronDown />
        </div>
      )}
    </div>
  );
}
