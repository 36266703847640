'use client';
/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from 'react';
import { useRouter as useNextRouter } from 'next/navigation';
import {
  NavigateFunction,
  useNavigate as useReactDomNavigate,
} from 'react-router-dom';
import { isChromeExtension } from '@reshima/shared';

function isReactDomNavigate(router: unknown): router is NavigateFunction {
  return isChromeExtension();
}

export function useNavigate() {
  const navigate = isChromeExtension()
    ? useReactDomNavigate()
    : useNextRouter();

  const navigateFunction = useCallback(
    (path: string) => {
      if (isReactDomNavigate(navigate)) {
        navigate(path);
      } else {
        navigate.push(path);
      }
    },
    [navigate]
  );

  return navigateFunction;
}
