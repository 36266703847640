'use client';

import { useEffect } from 'react';
import { ActionModifier, trackEvent } from '@reshima/telemetry';

export function PageTelemetry({ name }: { name: string }) {
  const action = 'Page';
  const start = Date.now();

  trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
  });

  useEffect(() => {
    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
    });
  }, [name, action, start]);

  return null;
}
